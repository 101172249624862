<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import attachmentModule from '@/store/main/customer/attachment'
import config from '@/views/main/customers/view/attachments/config'

export default {
  name: 'Address',
  created() {
    this.$emit('updateMenu', 'home-customers-attachments')
  },
  setup() {
    const { MODULE_NAME } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, attachmentModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>
