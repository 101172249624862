import {can} from "@core/libs/acl/utils";

export default function config() {
  const ACCESS_ABILITY_FOR_ATTACHMENTS = { action: 'change', subject: 'Attachments' }
  const tableColumns = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: can(ACCESS_ABILITY_FOR_ATTACHMENTS.action, ACCESS_ABILITY_FOR_ATTACHMENTS.subject) ? '10%' : '0' },
    },
    { key: 'name', label: 'File Name' },
    { key: 'created_at', label: 'Date' },
    { key: 'attachment_type', label: 'Type' },
    { key: 'attachment_category', label: 'Category' },
  ]
  const attachmentTrashTableColumns = [
    { key: 'actions', label: '' },
    { key: 'name', label: 'File Name' },
    { key: 'updated_at', label: 'Deleted Date' },
    { key: 'attachment_type', label: 'Type' },
    { key: 'attachment_category', label: 'Category' },
  ]

  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const MODULE_NAME = 'attachment'

  return {
    tableColumns,
    dateOptions,
    attachmentTrashTableColumns,
    MODULE_NAME,
    ACCESS_ABILITY_FOR_ATTACHMENTS,
  }
}
